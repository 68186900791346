// Here you can add other styles

.restaurant-name-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.restaurant-name-text {
  color: #3c4b64;
  font-size: 24px;
  font-weight: 500;
}
